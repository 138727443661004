import Vue from 'vue'
import '../modal/modal.vue'
import '../carousel-swiper/carousel-swiper.vue'

export default el => new Vue({
  name: 'Promobar',
  el,
  data() {
    return {
      isOpenModal: false,
      index: 0
    }
  },
  methods: {
    closeModal () {
      this.isOpenModal = false
      this.index = 0
    },
    openModal (index) {
      this.isOpenModal = true
      this.index = index
    }
  }
})